import { Flex, Spin } from "antd";
import { HTMLAttributes, Suspense, useMemo } from "react";

import { iconsListing } from "./icons-listing";

// To ease using the pre-defined icon names in the icons-listing.ts
export type IconName = keyof typeof iconsListing;

interface Props extends HTMLAttributes<HTMLDivElement> {
  icon: IconName;
  className?: string;
  rotate?: number;
}

/**
 * @param icon string key icon name
 * @param className string classes for styling
 * @param rotate optional number rotation of the icon which would be useful for arrow icons
 * @returns Icon react component
 */
export const Icon = ({ icon, className = "", rotate = 0, ...rest }: Props) => {
  const SvgIcon = useMemo(() => iconsListing[icon], [icon]);

  if (!SvgIcon) return null;

  return (
    <Flex
      justify="center"
      align="center"
      className={className}
      aria-label={icon}
      role="img"
      style={{
        transform: rotate ? `rotate(${rotate}deg)` : undefined,
      }}
      {...rest}
    >
      <Suspense fallback={<Spin />}>
        <SvgIcon />
      </Suspense>
    </Flex>
  );
};
