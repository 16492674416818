import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import {
  buildPromptAPI,
  generateResponseAPI,
  selectSubGraphAPI,
} from "api/question";
import {
  IPromptRequest,
  ISubGraphRequest,
  ISubGraphResponse,
} from "models/question.model";
import { InterceptedResponse, Status } from "models/shared";

interface QuestionAnswerState {
  isSelectingSubGraph: Status;
  isBuildingPrompt: Status;
  isGeneratingResponse: Status;
  subGraphResponse: ISubGraphResponse;
  prompt: string;
  questionsAnswer: string;
}

const initialState = {
  isSelectingSubGraph: "idle",
  isBuildingPrompt: "idle",
  isGeneratingResponse: "idle",
} as QuestionAnswerState;

export const selectSubGraph = createAsyncThunk(
  "question/selectSubGraph",
  async (request: ISubGraphRequest) => {
    const response = await selectSubGraphAPI(request);
    return response;
  }
);

export const buildPrompt = createAsyncThunk(
  "question/buildPrompt",
  async (request: IPromptRequest) => {
    const response = await buildPromptAPI(request);
    return response;
  }
);

export const generateResponse = createAsyncThunk(
  "question/generateResponse",
  async (prompt: string) => {
    const response = await generateResponseAPI(prompt);
    return response;
  }
);

export const questionSlice = createSlice({
  name: "question",
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(selectSubGraph.pending, (state: QuestionAnswerState) => {
        state.isSelectingSubGraph = "loading";
      })
      .addCase(
        selectSubGraph.fulfilled,
        (state: QuestionAnswerState, { payload }) => {
          state.isSelectingSubGraph = "succeeded";
          state.subGraphResponse = (payload as InterceptedResponse).data;
        }
      )
      .addCase(selectSubGraph.rejected, (state: QuestionAnswerState) => {
        state.isSelectingSubGraph = "failed";
      })
      .addCase(buildPrompt.pending, (state: QuestionAnswerState) => {
        state.isBuildingPrompt = "loading";
      })
      .addCase(
        buildPrompt.fulfilled,
        (state: QuestionAnswerState, { payload }) => {
          state.isBuildingPrompt = "succeeded";
          state.prompt = (payload as InterceptedResponse).data;
        }
      )
      .addCase(buildPrompt.rejected, (state: QuestionAnswerState) => {
        state.isBuildingPrompt = "failed";
      })
      .addCase(generateResponse.pending, (state: QuestionAnswerState) => {
        state.isGeneratingResponse = "loading";
      })
      .addCase(
        generateResponse.fulfilled,
        (state: QuestionAnswerState, { payload }) => {
          state.isGeneratingResponse = "succeeded";
          state.questionsAnswer = (payload as InterceptedResponse).data;
        }
      )
      .addCase(generateResponse.rejected, (state: QuestionAnswerState) => {
        state.isGeneratingResponse = "failed";
      });
  },
});

export default questionSlice.reducer;
