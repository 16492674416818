export const baseUrl = process.env.REACT_APP_GRAPH_RAG_URL;

export const DEFAULT_ERROR_MSG =
  "Something went wrong, Please try again later.";

export const DEFAULT_PROMPT_TEMPLATE = `""Bitte beantworten Sie die folgende Frage ausschließlich mit den Informationen aus dem beigefügten Kontext.

Frage: {user_question}

Kontext: {context}
""`;
