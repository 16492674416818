import { Menu, MenuProps } from "antd";
import { MenuInfo } from "rc-menu/lib/interface";
import { useNavigate } from "react-router";

import { Icon } from "icons";

enum NavItems {
  QuestionAnswer = "/question-answer",
  Settings = "/settings",
}

const navItems: MenuProps["items"] = [
  {
    label: "Q/A",
    key: NavItems.QuestionAnswer,
    icon: <Icon icon="Question" />,
  },
  {
    label: "Settings",
    key: NavItems.Settings,
    icon: <Icon icon="Settings" />,
    ...{ "data-testid": "settings" },
  },
];

const NavMenu = () => {
  const navigate = useNavigate();

  const handleMenuItemClick = ({ key }: MenuInfo) => {
    navigate(key);
  };
  return (
    <Menu
      defaultSelectedKeys={[NavItems.QuestionAnswer]}
      items={navItems}
      onClick={handleMenuItemClick}
      mode="horizontal"
      className="nav-menu"
    />
  );
};

export default NavMenu;
