import { configureStore } from "@reduxjs/toolkit";

import questionSlice from "./question-slice";

export const store = configureStore({
  reducer: {
    questionSlice,
  },
});

export type RootState = ReturnType<typeof store.getState>;

// Infer the `RootState` and `AppDispatch` types from the store itself
export type AppDispatch = typeof store.dispatch;
