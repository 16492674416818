import { IPromptRequest, ISubGraphRequest } from "models/question.model";

import { axiosConfig } from "./axios";

export const selectSubGraphAPI = async (request: ISubGraphRequest) => {
  const { queryText } = request;
  const url = `select-subgraph?queryText=${queryText}`;
  const response = await axiosConfig("GET", url);
  return response;
};

export const buildPromptAPI = async (request: IPromptRequest) => {
  const url = `build-prompt`;
  const response = await axiosConfig("POST", url, request);
  return response;
};

export const generateResponseAPI = async (prompt: string) => {
  const url = `generate-llm-response`;
  const response = await axiosConfig("POST", url, { prompt });
  return response;
};
