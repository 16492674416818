import { Flex, Layout } from "antd";

import { Icon } from "icons";

import NavMenu from "./NavMenu";

const { Header } = Layout;

type Props = {
  headerClass: string;
};

const NavHeader = ({ headerClass }: Props) => (
  <Header className={headerClass} data-testid="app-header">
    <Flex align="center">
      <Flex justify="center">
        <Icon icon="Logo" />
      </Flex>
      <NavMenu />
    </Flex>
  </Header>
);

export default NavHeader;
