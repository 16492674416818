import axios from "axios";

import { InterceptedResponse } from "models/shared";
import { baseUrl } from "utils/constants";

export const axiosConfig = (
  method: string,
  url: string,
  configData?: any,
  isFormData?: boolean
): Promise<InterceptedResponse | any> =>
  axios({
    method,
    url: `${baseUrl}${url}`,
    data: configData,
    headers: {
      "Content-Type": isFormData ? "multipart/form-data" : "application/json",
    },
  })
    .then((response) => {
      const { status, statusText, data } = response;
      return { error: false, message: statusText, status, data: data || {} };
    })
    .catch(async (err) => {
      const { data, status } = err.response;
      return {
        error: true,
        message: data.errorMessage,
        status,
        errors: data?.errors || [],
      } as InterceptedResponse;
    });
